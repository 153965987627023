import { Modal } from "react-bootstrap";
import { useConfig } from "../contexts/ConfigContext";

function LogoutModal(props){
  const {config,updateConfig} = useConfig()
  const handleClose = () => updateConfig({showLogout: false})
  const handleLogout = () => props.logout()
  return (
    <Modal show={config.showLogout} onHide={handleClose}>
      <Modal.Header >
        <Modal.Title>
          <h5 id="exampleModalLabel" className="modal-title">Confirma tu decisión</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <p>
            Al terminar tu sesión, se perderán los cambios que no hayas guardado
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" type="button" onClick={handleClose}>Cancelar</button>
          <button id="acept-btn" type="button" className="btn btn-primary" onClick={handleLogout}>Aceptar</button>
        </Modal.Footer>
    </Modal>
  )
}

export default LogoutModal