import React,{useEffect} from "react"
import useAuth from "./hooks/useAuth"
import ProgressModal from "./components/ProgressModal"
import { useConfig } from "./contexts/ConfigContext"
import { useCompany } from "./contexts/CompanyContext"
import { useUser } from "./contexts/UserContext"
import { useHistory } from "react-router-dom/cjs/react-router-dom"

function App(){
  const params = new URLSearchParams(window.location.search)
  const session = params.get('session')
  const app = params.get('app') || 'BASE'
  const {updateConfig} = useConfig()
  const {updateCompanies} = useCompany()
  const {updateUser} = useUser()
  const history = useHistory()

  const {tokenData} = useAuth.useToken('authCsrfToken',session)
  const {data:baseData} = useAuth.useLoadBase(tokenData,session,app)

  useEffect(()=>{
    if(baseData){
      updateConfig({
        showProgress: false,
        key:baseData.key,
        session
      })
      //permisos insuficientes
      if(baseData.status === 401 && baseData.redirect){
        window.location.href = baseData.redirect
        return
      }
      
      if(baseData.redirect.includes('base')){
        history.push('/index')
      }else{
        window.location.href = baseData.redirect + '/?session=' + baseData.session
      }
    }
  },[tokenData,baseData,updateConfig,updateCompanies,updateUser,history])

  return(
    <div className="container-fluid pt-4 min-vh-100" style={{backgroundColor:"var(--light)"}}>
      <h1 className="h3 mb-4 text-gray-800 text-center">Please wait for a moment...</h1> 
      <ProgressModal />
    </div>
  )
}

export default App