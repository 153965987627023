import React, { createContext, useContext, useState, useCallback } from 'react';
const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState({
    showProgress: true,
    showLogout: false,
    session: '',
    key: ''
  });
  const updateConfig = useCallback((newConfig) => {
    setConfig((prevConfig) => ({ ...prevConfig, ...newConfig }));
  }, []);

  return (
    <ConfigContext.Provider value={{ config, updateConfig }}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  return useContext(ConfigContext);
};