import { useCompany } from "../contexts/CompanyContext"
import { useConfig } from "../contexts/ConfigContext"

function Content(){
  const {companies} = useCompany()
  const { config } = useConfig()
  const handleClick = (path) => {
    const session = config.session
    console.log(path + '?session=' + session)
    //window.location.href = path + '?session=' + session
  }
  //console.log('Load content ',companies.current)
  return (
    <div className="container-fluid min-vh-100">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">{companies.current?.name}</h1>
        <img className="img-profile" style={{maxHeight:"60px"}} src={companies.current?.icon} alt=""/>
      </div>     
      <div className="row">
        { 
          companies.current?.modules?.map((d,i) =>{
            return (
              <div key={i} className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-primary shadow h-100 py-2">
                  <div className="card-body" style={{cursor:"pointer"}} onClick={() => handleClick(d.path)}>
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">{d.name}</div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">{d.description}</div>
                      </div>
                      <div className="col-auto">
                        <i className={d.icon || "fa-solid fa-briefcase"}></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }

      </div>
    </div>
  )
}

export default Content