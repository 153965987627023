import React, { createContext, useContext, useState, useCallback } from 'react';
const CompanyContext = createContext();

export const CompanyProvider = ({ children }) => {
  const [companies, setCompanies] = useState({
    current: {name: ''},
    list: []
  });
  const updateCompanies = useCallback((newConfig) => {
    setCompanies((prevConfig) => ({ ...prevConfig, ...newConfig }));
  }, []);

  return (
    <CompanyContext.Provider value={{ companies, updateCompanies }}>
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompany = () => {
  return useContext(CompanyContext);
};