import BlueBg from "./containers/BlueBg"
import { useConfig } from "./contexts/ConfigContext"

function Error(){
  const { updateConfig } = useConfig()
  updateConfig({
    showProgress: false
  })
  return (
    <>
      <BlueBg>
        <div className="bg-gradient-secondary">
          <div className="conatiner">
            <div className="text-center">
              <h1 className="h4 text-gray-900 mb-4 pb-3 pt-2">
                Lo sentimos!
              </h1>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="text-center">
            <div className="error mx-auto" data-text="404">
              404
            </div>
            <p className="text-gray-500 mb-3 mt-3">
              Parece que algo no esta funcionando...
            </p>
          </div>
        </div>
      </BlueBg>
    </>
  )
}

export default Error