import React from 'react'
import ProgressModal from './ProgressModal'
import Footer from './Footer'
import Base from './Base'

function Index(){

  return (
      <React.Fragment>
        <Base></Base> 
        <ProgressModal />
        <Footer />
      </React.Fragment>
  );
}


export default Index;