import {BrowserRouter as Router,Route,Switch} from 'react-router-dom'
import App from './App'
import Error from './404'
import Index from './components/Index'



function Routes(){
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={App}/>
        <Route exact path="/index" component={Index}/>
        <Route component={Error} />
      </Switch>
    </Router>
  )
}

export default Routes