import {Modal} from 'react-bootstrap'
import { useConfig } from '../contexts/ConfigContext';

function ProgressModal(){
  const {config, updateConfig} = useConfig()
 
  const handleClose = () => updateConfig({showProgress: false});
  return(
    <Modal show={config.showProgress} onHide={handleClose}>
      <Modal.Body>
        <div className="progress">
          <div 
            className=" progress-bar progress-bar-striped progress-bar-animated" 
            role="progressbar" 
            aria-valuenow="75"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{width: "75%"}}>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ProgressModal