import { useCompany } from "../contexts/CompanyContext"
import { useUser } from "../contexts/UserContext"
import { useConfig } from "../contexts/ConfigContext"

function TopBar(){

  const {companies, updateCompanies} = useCompany()
  const {user} = useUser()
  const { updateConfig } = useConfig()


  const search = () =>{
    const id = document.getElementById("searchUser").value
    console.log(id)
  }

  const loadModule = () => {
    alert('Modulo en construcción')
  }
  
  //console.log('Load Top ',data)
  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      
      <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
        <div className="input-group">
          <input type="text" id="searchUser" className="form-control bg-light border-0 small" placeholder="Busqueda de módulos o funciones" aria-label="Search" aria-describedby="basic-addon2"/>
          <div className="input-group-append">
            <button className="btn btn-primary" type="button" onClick={() => search()}>
              <i className="fas fa-search fa-sm"></i>
            </button>
          </div>
        </div>
      </form>
    
      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown no-arrow d-sm-none">
          <a className="nav-link dropdown-toggle" href="#!" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fas fa-search fa-fw"></i>
          </a>
          <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
            <form className="form-inline mr-auto w-100 navbar-search">
              <div className="input-group">
                <input type="text" className="form-control bg-light border-0 small" placeholder="Busqueda de módulos o funciones" aria-label="Search" aria-describedby="basic-addon2"/>
                <div className="input-group-append">
                  <button className="btn btn-primary" type="button" onClick={() => search()}>
                    <i className="fas fa-search fa-sm"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </li>


        <li className="nav-item dropdown no-arrow mx-1">
          <a className="nav-link dropdown-toggle" href="#!" id="messagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fas fa-solid fa-briefcase"></i>
          </a>
          <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="messagesDropdown">
            <h6 className="dropdown-header">
              Perfiles
            </h6>  
            {
              companies.list.map((e,i)=>{
                return (
                  <a key={i} className="dropdown-item d-flex align-items-center" href="#!" onClick={()=>updateCompanies({current: e}) }>
                    <div className="dropdown-list-image mr-3">
                      <img className="rounded-circle" src={e?.icon} alt=""/>
                      <div className="status-indicator bg-success"></div>
                    </div>
                    <div className="font-weight-bold">
                      <div className="text-truncate">{e.name}</div>
                      <div className="small text-gray-500">Ultimo ingreso · 58m</div>
                    </div>
                  </a>
                )
              })
            }
          </div>
        </li>

        <div className="topbar-divider d-none d-sm-block"></div>

        <li className="nav-item dropdown no-arrow">
          <a className="nav-link dropdown-toggle" href="#!" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className="mr-2 d-none d-lg-inline text-gray-600 small">{user.name}</span>
            <img className="img-profile rounded-circle" src={user.image || '/assets/images/user-regular.svg'} alt=""/>
          </a>
          <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
            <a className="dropdown-item" href="#!" onClick={() => loadModule()}>
              <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
              Profile
            </a>
            <a className="dropdown-item" href="#!" onClick={() => loadModule()}>
              <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
              Settings
            </a>
            <a className="dropdown-item" href="#!" onClick={() => loadModule()}>
              <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
              Activity Log
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" href="#!" data-toggle="modal" onClick={() => updateConfig({showLogout:true})}>
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400">{'Salir'}</i>
            </a>
          </div>
        </li>
      </ul>
    </nav>
  )
}

export default TopBar