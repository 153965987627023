import React, { createContext, useContext, useState, useCallback } from 'react';
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({name: ""});
  const updateUser = useCallback((newConfig) => {
    setUser((prevConfig) => ({ ...prevConfig, ...newConfig }));
  }, []);

  return (
    <UserContext.Provider value={{ user, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};