import React, { useEffect } from 'react'
import TopBar from './TopBar'
import Content from './Content'
import LogoutModal from './LogoutModal'
import Request from '../services/fetchDataService'
import { useConfig } from '../contexts/ConfigContext'
import { useCompany } from '../contexts/CompanyContext'
import { useUser } from '../contexts/UserContext'
import useAuth from '../hooks/useAuth'

function Base(){
  const { config,updateConfig } = useConfig()
  const { updateCompanies } = useCompany()
  const { updateUser } = useUser()

  const {tokenData} = useAuth.useToken('authCsrfToken',config.session)
  const {data:baseData} = useAuth.useLoadBase(tokenData)
  const {data:indexData} = useAuth.useIndex(tokenData,config.session)

  useEffect(()=>{
    if(baseData && indexData){
      if(indexData.companies.length === 1 && indexData.companies[0].modules.length === 1){
        const redirect = indexData.companies[0].modules[0].path + '/?session=' + tokenData.session
        console.log("Redireccion a: ",redirect)
        window.location.href = redirect
        return
      }
      updateConfig({showProgress:false,session:tokenData.session})
      updateUser(indexData.user)
      updateCompanies({list:indexData.companies,current:indexData.companies[0]})
    }
  },[baseData,indexData,updateCompanies,updateUser])
  const handleLogout = () => {
    updateConfig({showProgress:true})
    Request.post('/security/auth/logout',{},config.key)
    .then(res => {
      updateConfig({showProgress:false})
      if(res.status === 200) window.location.href = res.redirect
    }
    ).catch(err => window.location.href = 'error')
  }

  return (
    <div id="page-top">
      <div id="wrapper">
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TopBar/>
            <Content/>
          </div> 
        </div>
      </div>
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
      <LogoutModal logout={handleLogout}/>
    </div>
  )
}

export default Base