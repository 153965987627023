import useSWR from 'swr'
import fetchDataService from '../services/fetchDataService'
import { generateFrontKeys } from '../services/securityService'


function useLoadBase(token,session,app){
  const config = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
  }
  
  const fetcher = () => fetchDataService.post('/security/base/load-base',{session,app},token.key)  
  const {data,error} = useSWR(()=>token ? '/security/base/load-base':null,fetcher,config)

  return {
    data,
    error
  }
}

function useIndex(token,session){
  const config = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
  }
  const fetcher = () => fetchDataService.post('/security/base/index',{session},token.key)  
  const {data,error} = useSWR(()=>token  ? '/security/base/index': null,fetcher,config)

  return {
    data,
    error
  }
}

function useToken(typeToken,session){
  const frontKeyFetcher = async () => {
    await generateFrontKeys
    const key = localStorage.getItem('frontKey')
    return {key}
  }
  const {data:frontKey} = useSWR('generatefrontkeys',frontKeyFetcher)

  const serverKeyFetcher = () => fetchDataService.post('/public/key')
  const tokenFetcher = (args) => fetchDataService.post(args[0],{
      typeToken,
      timeToken: 5,
      session
    },args[1]) 
  
  const {data:keyData} = useSWR(()=> frontKey ? '/public/key':null,serverKeyFetcher)
  const {data:tokenData} = useSWR(()=>keyData ? ['/security/auth/create-authsession',keyData.data]:null,
    tokenFetcher
  )
  if(tokenData?.status === 401){
    window.location.href = tokenData.redirect
  }
  return {
    tokenData,
  }
}

export default {
  useLoadBase,
  useIndex,
  useToken
}