import React from 'react';
import {createRoot} from 'react-dom/client'
import { ConfigProvider } from './contexts/ConfigContext';
import Routes from './Routes';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './contexts/UserContext';
import { CompanyProvider } from './contexts/CompanyContext';

const domNode = document.getElementById('root')
const root = createRoot(domNode)

root.render(
  <ConfigProvider>
    <UserProvider>
      <CompanyProvider>
        <Routes/>
      </CompanyProvider>
    </UserProvider>
  </ConfigProvider>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
